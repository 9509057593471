import React, { useState, useEffect, useMemo } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { normalizeText } from '@cms/utils'
import PropTypes from 'prop-types'

import Layout from 'components/layout'
import SEO from 'components/seo'

import { Title } from '../components/Isurance'
import {
  PageContainer,
  InsuranceList,
  Insurance,
  InsuranceName,
  ErrorState,
  ErrorMessage,
  ResetButton,
  SearchBar,
  Magnifier,
  SearchContainer,
  Wrapper,
  ErrorMessageWrapper,
} from '../components/Isurance/styled'



const InsurancePage = ({ pageContext, location }) => {
  const data = useStaticQuery(graphql`
    {
      allInsurance {
        nodes {
          id
          name
        }
      }
      wordpressWpGatsbyPage(slug: { eq: "convenios" }) {
        acf {
          title
          description
          canonical
        }
      }
    }
  `)

  const { title, description, canonical } = data.wordpressWpGatsbyPage.acf
  const insurances = data.allInsurance.nodes

  const [searchQuery, setSearchQuery] = useState('')
  const [searchedData, setSearchedData] = useState(insurances)


  const resetSearchData = () => {
    setSearchQuery('')
    setSearchedData(insurances)
  }

  const search = () => {
    setSearchedData(insurances.filter(({ name }) => normalizeText(name).includes(normalizeText(searchQuery))))
  }

  const handleKeyDown = (event) => {
    const keyCodes = ['Enter']

    if (keyCodes.includes(event.key)) {
      event.preventDefault()
      search()
    }
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      search()
    }, 1500)

    return () => clearTimeout(delayDebounceFn)
  }, [searchQuery])

  return (
    <Layout pageContext={pageContext} location={location}>
      <SEO title={title} description={description} canonical={canonical} />
      <PageContainer>
        <Title>Convênios</Title>

        <Wrapper>
          <SearchContainer>
            <SearchBar
              onKeyDown={handleKeyDown}
              placeholder="Pesquise seu convênio..."
              type="text" value={searchQuery}
              onChange={(event) => setSearchQuery(event.target.value)} />
            <Magnifier size={20} alt="lupa" onClick={search} />
          </SearchContainer>
        </Wrapper>

        <InsuranceList>
          {useMemo(() => searchedData.map(({ name, id }) => (
            <Insurance key={id}>
              <InsuranceName>- {name}</InsuranceName>
            </Insurance>
          )), [searchedData])}
        </InsuranceList>

        {searchedData?.length === 0 && (
          <ErrorState>
            <ErrorMessageWrapper>
              <ErrorMessage>Não encontramos nenhum convênio com esse nome</ErrorMessage>
              <ErrorMessage>Contate a sua operadora</ErrorMessage>
            </ErrorMessageWrapper>
            <ResetButton onClick={resetSearchData}>Listar todos convênios</ResetButton>
          </ErrorState>
        )}
      </PageContainer>
    </Layout >
  )
}

InsurancePage.propTypes = {
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object,
}

export default InsurancePage
