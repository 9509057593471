import styled from 'styled-components'
import { Container } from '../shared/container'
import media from 'styled-media-query'
import { Search } from '@styled-icons/bootstrap/Search'

export const TitleStyled = styled.h1`
  color: ${props => props.theme.colors.secondary};
  font-size: 4.2rem;
  margin-top: 4.8rem;
  margin-bottom: 3.2rem;
  text-align: center;
  ${media.lessThan('large')`
    text-align: left;
  `}
`

export const PageContainer = styled(Container)`
  margin-bottom: 1.6rem;
`

export const InsuranceList = styled.ul`
  list-style: none;
  margin-top: 0;
  margin-bottom: 3.2rem;
  padding-left: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  grid-gap: 0.4rem;

  ${media.lessThan('large')`
    grid-template-columns: 1fr;
  `}
`

export const Insurance = styled.li`
  box-sizing: border-box;
  width: 100%;
`

export const InsuranceName = styled.p`
  font-size: 1.4rem;
  color: ${props => props.theme.baseColors.secondary};
  margin: 0.4rem;
`

export const ErrorState = styled.div`
  width: 100%;
  text-align: center;
  margin: 12rem 0;
`

export const ErrorMessageWrapper = styled.div`
  margin-bottom: 9.4rem;
`

export const ErrorMessage = styled.h4`
  font-size: 21px;
  line-height: 24px;
  color: ${props => props.theme.baseColors.secondary};
`

export const ResetButton = styled.div`
  color: ${props => props.theme.colors.secondary};
  cursor: pointer;
  text-decoration: underline;
  font-size: 16px;
  line-height: 18px;
`

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const SearchContainer = styled.div`
  width: 80%;
  position: relative;
  display: flex;
  margin-bottom: 4.8rem;

  ${media.lessThan('large')`
    width: 100%;
  `}
`

export const SearchBar = styled.input`
  width: 100%;
  border-bottom: 0.2rem solid ${props => props.theme.baseColors.secondary};
  border-top: none;
  border-left: none;
  border-right: none;

  padding: 8px;
  height: 20px;
  outline: none;
  color: ${props => props.theme.baseColors.secondary};
`

export const Magnifier = styled(Search)`
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: ${props => props.theme.baseColors.secondary};
  cursor: pointer;
`
